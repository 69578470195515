import React, { useEffect, useState, useRef } from "react";
import { styled } from '@mui/material/styles';
import apiService from '../../services/api';
import { toast } from "react-toastify";
import tokenService from "../../services/tokenService";
import { IconButton, Tooltip, Grid, colors } from "@mui/material";
import  Download  from '@mui/icons-material/GetApp';
import axios from "axios";
import PreviewIcon from '@mui/icons-material/Preview';
import ReactLoading from 'react-loading';
import './styleReception.css'
const API_URL = process.env.REACT_APP_API_URL;
let token;

const IconButtonStyled = styled(IconButton)(({
  theme: {palette,mode}
}) => ({
  fontSize: "27px",
  padding: "7px 8px 7px 8px",
  fontWeight: "bolder",
  color: palette.info[mode],
  '&:hover': {
    color: colors.indigo[600],
  },
}))

export default function SharedFiles ({handleOnclickImg}){
    const [sharedFiles, setSharedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    
    useEffect(() => {
      readSharedFilesList()
      token = tokenService.get();
    }, [])

    const readSharedFilesList = async () => {
      try{
        setIsLoading(true)
        const apiCheckRes = await apiService.checkSharedFiles();
        const apiCheckData = apiCheckRes.data
        if(apiCheckData.code !== 0) {
          toast.error(apiCheckData.msg, {autoClose: 10 * 1000})
        }
        if(apiCheckData.data.newArr) {
          let sharedFilesTmp = []
          for(let newFile of apiCheckData.data.newArr) {
            let sFile = {...newFile, src: "", type: "pdf", batchID: "Company Shared Files"}
            const url = API_URL + `desktop/shared-files/${newFile.id}?token=${token}`
            const response = await axios.get(url, { responseType: 'arraybuffer' })
            sFile.fullFile = response.data;
            const blob = new Blob([response.data], {type: "application/pdf"})
            const reader = new FileReader();
            reader.onloadend = () => {
              // dont user blob as dataUrl. doesn't work on firefox on production for some reason?!
              sFile.src = reader.result + '#toolbar=0&navpanes=0&scrollbar=0'
              sharedFilesTmp.push(sFile)
              setSharedFiles([...sharedFilesTmp])
            };
            reader.readAsDataURL(blob);
          }
        }
      } catch (err) {
        console.error(err)
        toast.error(err.message, {autoClose: 10000})
      } finally {
        setIsLoading(false)
      }
    }

    const downloadSharedFile = async (sFile) => {
      var link = document.createElement("a");
      link.href = sFile.src;
      link.download = sFile.name;
      link.click()
    }

    return (
      <>
        {isLoading && 
          <div style={{
            position: "absolute",
            width: "50px",
            height: "50px",
            top: "50%",
            left: "50%",
            margin: "-25px 0 0 -25px",
          }}>
            <ReactLoading type={"spin"} color={"#0085d2"} />
          </div>
        }
        {sharedFiles[0] && sharedFiles.map((sFile, index) => 
          <Grid key={index} className='shared-file-item mt-2'>
            <embed className='shared-file-object' src={sFile.src + "#view=FitH&toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0"} />
            <div className='shared-file-actions '> 
              <Tooltip title='Download this file'>
                <IconButtonStyled className='shared-file-download-icon' onClick={(ev)=>{ev.stopPropagation(); downloadSharedFile(sFile)}} >
                  <Download />
                </IconButtonStyled>
              </Tooltip>
              <Tooltip title='Preview this file'>
                <IconButtonStyled className='shared-file-download-icon' onClick={(ev)=>{ev.stopPropagation(); handleOnclickImg(sFile)}} >
                  <PreviewIcon />
                </IconButtonStyled>
              </Tooltip>
            </div>
          </Grid>
        )}
      </>
    );
}

