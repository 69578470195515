import React, { useEffect, useCallback, useState, useRef } from 'react';
import { styled, makeStyles, useTheme } from '@mui/material/styles';
import {  } from '@mui/material/styles';
import LocalUserView from './LocalUserView';
import RemoteUserView from './RemoteUserView';
import { useSelector, useDispatch } from 'react-redux';
import { addMic, selectMic, deleteMic, deleteMics, updateDefaultMic } from '../../redux/micSlice';
import { addCamera, selectCamera, deleteCamera, deleteCameras } from '../../redux/cameraSlice';
import { addSpeaker, selectSpeaker, deleteSpeaker, deleteSpeakers, updateSpeaker } from '../../redux/speaker';
import { toggleReceptionSidebar } from '../../redux/receptionSidebar';
import Str from '../../constants/string';
import Constant from '../../constants/constant';
import Storages from '../../constants/storages';
import { ButtonBase, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import Messanger from '../Reception/Messanger';
import { useMemo } from 'react';
import { useContext } from 'react';
import { SocketContext } from '../../context/socket';
import { addMessage ,addUnReadMessage, setUnReadMessages} from '../../redux/messages';
import apiService from "../../services/api"
import { toast } from 'react-toastify';
import AudioMessage from '../../assets/sounds/tone.mp3'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import VSLogo from '../../assets/images/virtualsally_logo_h.png';
import './style.css'
import RttChart from './RttChart';
import CallTimeLabel from '../Controls/CallTimeLabel';

const PREFIX = 'UserArea';

const classes = {
    root: `${PREFIX}-root`,
    close_area: `${PREFIX}-close_area`,
    stage_area: `${PREFIX}-stage_area`,
    attendant_area: `${PREFIX}-attendant_area`,
    video_area: `${PREFIX}-video_area`,
    doctor_base_img: `${PREFIX}-doctor_base_img`,
    bottom_area: `${PREFIX}-bottom_area`,
    info_area: `${PREFIX}-info_area`,
    feature_area: `${PREFIX}-feature_area`,
    user_name: `${PREFIX}-user_name`,
    time_area: `${PREFIX}-time_area`,
    online_lab: `${PREFIX}-online_lab`,
    title_area: `${PREFIX}-title_area`,
    list_area: `${PREFIX}-list_area`,
    attendant_lab: `${PREFIX}-attendant_lab`
};

const Root = styled('div')(({
    theme: {palette,mode}
}) => ({
        display: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0px',
        backgroundColor:palette.background[mode],
        color:palette.color[mode],
    [`& .${classes.close_area}`]: {
        width:'100%',
        display:"flex",
        justifyContent: "space-between"
    },

    [`& .${classes.stage_area}`]: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        position: "relative",
    },

    [`& .${classes.attendant_area}`]: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        paddingBottom:"10px",
        overflowY:'auto'
    },

    [`& .${classes.video_area}`]: {
        display: 'flex',
        width: '100%',
        height: '78%',
        justifyContent: 'center',
        alignItems: 'center'
    },

    [`& .${classes.doctor_base_img}`]: {
        maxWidth: '90%',
        maxHeight: '90%',
        display: 'block',
        border: '6px solid #007cbb'
    },

    [`& .${classes.bottom_area}`]: {
        display: 'flex',
        width: '100%',
        padding: '5px 0',
        flexDirection: 'row',
        // justifyContent: 'center',
        alignItems: 'center'
    },

    [`& .${classes.info_area}`]: {
        display: 'flex',
        flexDirection: 'row',
        width: '70%',
    },

    [`& .${classes.feature_area}`]: {
        display: 'flex',
        width: '30%'
    },

    [`& .${classes.user_name}`]: {
        fontSize: 'max(1.14vw, 16px)',
        // fontFamily: 'Poppins',
    },

    [`& .${classes.time_area}`]: {
        display: 'flex',
        alignItems: 'flex-end',
        marginLeft: '20px'
    },

    [`& .${classes.online_lab}`]: {
        // fontFamily: 'poppins',
        fontSize: 'max(0.93vw, 14px)',
        marginRight: '5px'
    },

    [`& .${classes.title_area}`]: {
        alignItems: 'center',
        display: 'flex',
        // height: '15.8%'
    },

    [`& .${classes.list_area}`]: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingTop:"5px"
        // overflow: 'scroll',
    },

    [`& .${classes.attendant_lab}`]: {
        // fontFamily: 'SemiBold',
        fontWeight: 'bold',
        fontSize: 'max(1.35vw, 18px)',
        margin: '0px'
    }
}));

const ToggleUserSidebarButton = styled(ButtonBase)(({
  theme: {palette,mode}
}) => ({
  height: "45px",
  position: "absolute",
  display: "none",
  zIndex: "2",
  // border: "1px solid rgb(235, 235, 235)",
  borderRadius: "0px 20px 20px 0px",
  // marginTop: "46vh",
  backgroundColor: "rgb(225, 225, 225)",
  // backgroundColor: palette.background[mode],
  '& svg': {
    color: "rgba(40, 40, 40, 0.87)",
    // color: palette.color[mode],
  },
  right: "-30px",
}))

const UserArea = React.memo((props) => {
    const { userStatus, remoteUsers, joinState, localConnectionQuality } = props;

    const theme = useTheme();
    const dispatch = useDispatch();
    const mics = useSelector((state) => state.mics);
    const cameras = useSelector((state) => state.cameras);
    const speakers = useSelector((state) => state.speakers);
    const receptionSidebar = useSelector((state) => state.receptionSidebar)
    const [checkDevice, setCheckDevice] = useState(false);
    const [updateCameraDevice, setUpdateCameraDevice] = useState(false);
    const [updateMicDevice, setUpdateMicDevice] = useState(false);
    const [displayChatBox,setDisplayChatBox]=useState(false)
    const [connectionQualityColor, setConnectionQualityColor]=useState("")
    const [connectionQualityText, setConnectionQualityText]=useState("")
    const [statusColor, setStatusColor]=useState("")
    const myName = localStorage.getItem(Storages.LOCAL_RECEPTION_NAME)
    // const [openSidebar, setOpenSidebar]=useState(true)
    
    const [chatBy,setChatBy]=useState('')
    const chatByRef=useRef(chatBy)
    const _setChatByRef=(data)=>{
        chatByRef.current=data
        setChatBy(data)
    }
    const socket = useContext(SocketContext)
    const [chatByDisplayName,setChatByDisplayName]=useState('')
    const MessangerElement=useMemo(()=>Messanger,[displayChatBox,chatBy,chatByDisplayName])
    const [audio]=useState(new Audio(AudioMessage))

    useEffect(() => {
        // check devices timer
        let intervalDevices = setInterval(() => {
            setCheckDevice(prev => !prev);
        }, Constant.FIVE_SECONDS)
        socket.on('msg', async ({from,event, msg})=>{
            if(event==='chat'){
              try{
                var read_at=false
                if(from !==chatByRef.current) {
                    dispatch(addUnReadMessage({reception:from}))     
                    audio.play()
                } else {
                    const markReadRes = await apiService.readMessage({id: msg.id})
                    if(markReadRes.data.code !== 0) {
                      toast.error(markReadRes.data.msg, {autoClose: 10 * 1000})
                      return
                    }
                    read_at=true
                }
                dispatch(addMessage({reception:from,message:{...msg,read_at}}))
              } catch (err) {
                console.error(err)
                toast.error(err.message, {autoClose: 10 * 1000})
              }
            }
        })
        return (() => {
            clearInterval(intervalDevices);
            socket.off('msg')
        })
    }, [])

    useEffect(() => {
      if (userStatus === Constant.AWAY) {
        setStatusColor(theme.palette.warning[theme.palette.mode])
      } else if (userStatus === Constant.JOIN) {
        setStatusColor(theme.palette.success[theme.palette.mode])
      } else if (userStatus === Constant.CALL) {
        setStatusColor(theme.palette.pink[theme.palette.mode])
      } else {
        setStatusColor(theme.palette.error[theme.palette.mode])
      }
    }, [userStatus])

    const handleCheckDevice = useCallback(() => {
        if (!joinState) {
            return;
        }
        const currentCameras = [...cameras];
        const currentMics = [...mics];
        var currentSpeakers = [...speakers];

        if (window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(Str.STR_INPUT)) {
            window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
                const videoInputDevices = devices.filter((d) => d.kind === Str.STR_VIDEO_INPUT);
                const audioInputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_INPUT);
                const audioOutputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_OUTPUT);
                //add camera devices in redux when it is connected
                videoInputDevices.forEach((cell, i) => {
                    const index = currentCameras.findIndex((camera) => camera.deviceId === cell.deviceId)
                    if (index < 0) {
                        const item = { deviceId: cell.deviceId, label: cell.label, selected: false }
                        dispatch(addCamera(item));
                        if (currentCameras.length === 0 && i === 0) {
                            let cameraDeviceId = videoInputDevices[0].deviceId;
                            dispatch(selectCamera({ index: 0 }));
                            // localStorage.setItem(Storages.LOCAL_CAMERA_ID,cameraDeviceId)

                            // We don't have this for mics, why should we have it for camera?
                            // window.JitsiMeetJS.createLocalTracks({
                            //     devices: [Str.STR_VIDEO],
                            //     cameraDeviceId: cameraDeviceId
                            // })
                            // .then((tracks) => props.onLocalTracks(tracks))
                            // .catch(error => {
                            //     handleErrorTrack(error,1496,'camera')
                            // //   console.error(1496, error)
                            //   props.setCamera(false)
                            // //   toast.error(`There is an issue with video track. Please reload. 
                            // //     If this issue persists, please contact customer support.
                            // //   `)
                            // });
                        }
                    }
                })

                //delect camera device in redux when it is disconnected
                let isSelectedCamera = true;
                if (videoInputDevices.length > 0) {
                    currentCameras.forEach((cell) => {
                        const index = videoInputDevices.findIndex((camera) => camera.deviceId === cell.deviceId)
                        if (index < 0) {
                            dispatch(deleteCamera(cell));
                            if (cell.selected) {
                                isSelectedCamera = false;
                            }
                        }
                    })
                } else {
                  props.setCamera(false)
                  toast.error(`No camera found. Please reload. 
                    If this issue persists, please contact customer support.
                  `)

                  if (currentCameras.length > 0) {
                    dispatch(deleteCameras());
                  }
                }

                // if (!isSelectedCamera) {
                //     setUpdateCameraDevice(prev => !prev);
                // }



                // microphone
                if (audioInputDevices.length > 0) {
                    audioInputDevices.forEach((cell) => {
                      const index = currentMics.findIndex((mic) => mic.label === cell.label)
                      if (index < 0) {
                        if(cell.deviceId == "communications") {
                            return;
                        }
                        let newMic={
                          deviceId: cell.deviceId,
                          label: cell.label,
                        }
                        dispatch(addMic(newMic));
                      }
                    })
                    currentMics.forEach((m)=>{
                      let index=audioInputDevices.findIndex((mic) => mic.label === m.label)
                      if(index<0){
                        if(m.deviceId==='default'){
                          let input=audioInputDevices.find((mic) => mic.deviceId==='default')
                          dispatch(updateDefaultMic({deviceId:input.deviceId,label:input.label}))
                          return
                        }
                        dispatch(deleteMic({label:m.label}))
                        if(m.selected){
                          dispatch(selectMic({deviceId:audioInputDevices[0].deviceId}))
                        //   localStorage.setItem(Storages.LOCAL_MIC_ID,audioInputDevices[0].deviceId)
                        }
                      }
                    })
                }
                else {
                    if (currentMics.length > 0){
                    dispatch(deleteMics());
                    }
                }

                // speaker
                if (audioOutputDevices.length > 0) {
                    //add
                    audioOutputDevices.forEach((cell)=>{
                        const index = currentSpeakers.findIndex((s) => s.label === cell.label)
                        if (index < 0) {
                        if(cell.deviceId == "communications") {
                            return;
                        }
                        let label = cell.label;
                        let sp = {  deviceId: cell.deviceId, label}
                            dispatch(addSpeaker(sp));
                        }
                    })
                    // remove
                    currentSpeakers.forEach((sp)=>{
                        let index=audioOutputDevices.findIndex((s) => s.label === sp.label)
                        if(index<0){
                            if(sp.deviceId==='default'){
                            let out=audioOutputDevices.find((ao)=>ao.deviceId==='default')
                            if(out && out.label){
                                dispatch(updateSpeaker({deviceId:sp.deviceId,label:out.label}))
                            }
                            return
                            }
                            dispatch(deleteSpeaker({deviceId:sp.deviceId}))
                            if(sp.selected){
                            let deviceId=audioOutputDevices[0].deviceId
                            localStorage.setItem(Storages.LOCAL_SPEAKER_ID,deviceId)
                            dispatch(selectSpeaker({deviceId}))
                            document.getElementById('locationAudio').setSinkId(deviceId)
                            document.getElementById('mainRAudio').setSinkId(deviceId)
                            document.getElementById("testSpeaker").setSinkId(deviceId)
                            }
                        }
                    })
                }
                else if (currentSpeakers.length > 0){
                    dispatch(deleteSpeakers());
                }
            })
        }
    }, [checkDevice])

    useEffect(() => {
        handleCheckDevice();
    }, [checkDevice])


    // const handleUpdateCameraDevice = useCallback(() => {
    //     if (cameras.length > 0 && joinState) {

    //       let cameraDeviceId = localStorage.getItem(Storages.LOCAL_CAMERA_ID)
    //       if(!cameraDeviceId){
    //           cameraDeviceId = cameras[0].deviceId;
    //         //   localStorage.setItem(Storages.LOCAL_CAMERA_ID,cameraDeviceId)
    //           dispatch(selectCamera({ deviceId: cameraDeviceId }));
    //       }
    //         window.JitsiMeetJS.createLocalTracks({
    //             devices: [Str.STR_VIDEO],
    //             cameraDeviceId: cameraDeviceId
    //         })
    //         .then((tracks) => props.onLocalTracks(tracks))
    //         .catch(error => handleErrorTrack(error,1222,'camera'));
    //     }
    // }, [updateCameraDevice])

    // useEffect(() => {
    //     handleUpdateCameraDevice();
    // }, [updateCameraDevice])

    // const handleUpdateMicDevice = useCallback(() => {
    //     if (mics.length > 0 && joinState) {
    //         let micDeviceId = mics[0].deviceId;
    //         dispatch(selectMic({ index: 0 }));
    //         window.JitsiMeetJS.createLocalTracks({
    //             devices: [Str.STR_AUDIO],
    //             micDeviceId: micDeviceId
    //         })
    //         .then((tracks) => props.onLocalTracks(tracks))
    //         .catch(error => handleErrorTrack(error,1221,'microphone'));
    //     }
    // }, [updateMicDevice])

    // useEffect(() => {
    //     handleUpdateMicDevice();
    // }, [updateMicDevice])

    useEffect(() => {
      if(localConnectionQuality.connectionQuality === 0) {
        setConnectionQualityColor("white")
        setConnectionQualityText("Loading...")
      } else if (localConnectionQuality.connectionQuality < 30) {
        setConnectionQualityColor("#D91E18")
        setConnectionQualityText("Very poor")
      } else if (localConnectionQuality.connectionQuality < 60) {
        setConnectionQualityColor("#f3c200")
        setConnectionQualityText("Poor")
      } else if (localConnectionQuality.connectionQuality < 90) {
        setConnectionQualityColor("#26C281")
        setConnectionQualityText("Good")
      } else {
        setConnectionQualityColor("#26C281")
        setConnectionQualityText("Excellent")
      }
    }, [localConnectionQuality])

    const handleCloseChat=()=>{
        setDisplayChatBox(false)
        _setChatByRef('')
        setChatByDisplayName('')
    }
    const handleOpenChatBox=async(person,displayName)=>{
        _setChatByRef(person)
        setChatByDisplayName(displayName)
        setDisplayChatBox(true)
        dispatch(setUnReadMessages({reception:person,number:0}))
    }

    const toggleUserBar = () => {
      dispatch(toggleReceptionSidebar())
    }

    return (
      <div className='reception_left_sidebar' style={{marginLeft:(receptionSidebar)?0:'calc(-1 * var(--reception-left-sidebar-width))'}}>
        <Root className={classes.root}>
            <div className={classes.close_area} style={{backgroundColor: "rgb(225, 225, 225)"}}>
                <img style={{paddingLeft: "5px", height: "45px"}} src={VSLogo} />
                {/* <ButtonBase className='py-1' style={{color: "black"}}>
                  <CloseOutlinedIcon onClick={toggleUserBar}/>
                </ButtonBase> */}
            </div>
            <div className={classes.stage_area}>
              <Tooltip title={`Connection quality ${localConnectionQuality.connectionQuality}`}>
                <span className='pl-0 py-1 d-flex' 
                  style={{
                    color: connectionQualityColor,
                    backgroundColor:"#1C1D1E",
                    position:"absolute",
                    top: "0px",
                    left: "0px",
                    borderRadius:"5px",
                    padding:"3px",
                    alignItems:"center",
                  }}>
                  <SignalCellularAltIcon/>
                  <label style={{margin:'0 5px'}}>
                    {connectionQualityText}
                  </label>
                </span>
              </Tooltip>
              <video className='reception-self-video' autoPlay='1' id='mainRVideo' playsInline/>
              <audio autoPlay='1' muted='1' id='mainRAudio' />
              <div className='row w-100'>
                <div className='col-6 pl-3 pr-0 pt-2'>
                  <b><CallTimeLabel fontSize={'1'} duringTime={0} play={true} /></b>
                </div>
                <div className='col-6 px-0' style={{overflow: "hidden", maxWidth: "160px"}}>
                  <RttChart localConnectionQuality={localConnectionQuality}/>
                </div>
              </div>
            </div>
            <div className={classes.attendant_area} style={{paddingInline: "0.6rem"}}>
              <div className={classes.list_area}>
                <div className='remote_reception_area pr-2' style={{height: "34px"}}>
                  <FiberManualRecordRoundedIcon className='mr-1' style={{color: statusColor}} fontSize='small'/>
                  <label className='d-flex align-items-center reception_name text-truncate'>
                    Me ({myName})
                  </label>
                  {userStatus == 2 || userStatus == 3 ?
                    <FormControl variant="standard" style={{minWidth: 80}}>
                      <Select
                        labelId="receptionStatusLabel"
                        value={userStatus}
                        onChange={(event) => props.changeUserStatus(event.target.value)}
                        label="Status"
                      >
                        <MenuItem value={3}>Online</MenuItem>
                        <MenuItem value={2}>Away</MenuItem>
                      </Select>
                    </FormControl>
                  :
                    <span className='text-nowrap'>{userStatus == 0 ? "Connecting..." : userStatus == 5 ? "In call" : userStatus == 6 ? "Hold" : ""}</span>
                  }
                </div>
                {remoteUsers.map((remoteUser, index) => (
                  <RemoteUserView 
                    key={index} 
                    user_name={remoteUser.username} 
                    display_Name={remoteUser.displayName} 
                    user_status={remoteUser.status} 
                    location_name={remoteUser.otherpartid} 
                    handleOpenChat={handleOpenChatBox}
                  />
                ))}
              </div>
            </div>
            <MessangerElement display={displayChatBox} handleCloseChat={handleCloseChat} reception={chatBy} displayName={chatByDisplayName}/>
        </Root>
        <ToggleUserSidebarButton className='btn-no-outline' style={{display: "block"}} 
          onClick={toggleUserBar}>
            {
              receptionSidebar?
              <KeyboardArrowLeftOutlinedIcon fontSize='large'/>
              :<KeyboardArrowRightOutlinedIcon fontSize='large'/>
            }
        </ToggleUserSidebarButton>
      </div>
    );
});

export default UserArea;