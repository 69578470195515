import React, { Component, createRef } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import Storages from './constants/storages';
import { connect } from "react-redux";
import { setLocations } from './redux/locations';
import { isSleep } from './redux/sleepLocation';
import apiService from './services/api'
import { SocketContext, socket } from './context/socket';
import { MyThemeProvider } from './context/theme';
import ErrorBoundary from './ErrorBoundary';
import {CronJob} from 'cron'
import constant from './constants/constant';
import * as $ from 'jquery';
import { Confirm } from "./Components/Modal/ConfirmModal"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import tokenService from './services/tokenService';
import { OnLoad } from './Pages';
import TopTapImage from './assets/images/top_tap.png';
import BottomTapImage from './assets/images/bottom_tap.png';
import { detectIncognito } from "detectincognitojs";

// always keep at the end of imports
import './App.css'

const { version } = require('../package.json');

const browserHistory = createBrowserHistory();

let reloadSnoozed = false;

class App extends Component {
  confirmRef = createRef();

  constructor(props) {
    super(props);
    localStorage.setItem(Storages.LOCAL_APP_VERSION, version)
    this.state = {
      onLoad: true,
      replaceCalledFromOnLoad: false,
    };

    this.onLoadDone = this.onLoadDone.bind(this);
  }

  componentDidMount() {
    console.alert("App did mount");
    if(window.electron) {
      window.electron.electronRes((event, data)=> {
        if(data.toast) {
          let timeout = data.toastTimeOut || 5000
          if(data.code === 0) {
            toast.success(data.msg, {autoClose: timeout})
          } else if (data.code < 0) {
            toast.error(data.msg, {autoClose: timeout})
          } else {
            toast.info(data.msg, {autoClose: timeout})
          }
        }
      })
      if(process.env.NODE_ENV !='development') {
        this.checkLastVersion()
      }
    }
    this.socketListening()
    if(window.electron) this.checkLastVersionEveryMidnight()
    if (window.electron && 
      localStorage.getItem(Storages.LOCAL_ROLE) == "location" && 
      localStorage.getItem(Storages.LOCAL_TOKEN_USER) 
    ){
      if(process.env.NODE_ENV !='development') {  
        window.electron.kioskMode(true)
        var ontop=localStorage.getItem(Storages.LOCAL_ONTOP_MODE)
        if(ontop == "false")  {
          window.electron.setAlwaysOnTop(false)
        } else {
          window.electron.setAlwaysOnTop(true)
          localStorage.setItem(Storages.LOCAL_ONTOP_MODE,true)
        }
      }

      this.checkDefaultScanner();
      this.checkTfjs()
      window.electron.hideKioskView()
      window.electron.hideWebView()
    } else {
      window.electron && window.electron.setAlwaysOnTop(false)
    }
    window.confirmAsync = this.confirmRef.current
  }

  componentWillMount() {
    const noOnloadRoutes = ["#/forgotpassword", "#/success", '#/already-connected'];
    this.browserHistoryUnListen = browserHistory.listen(location => {
      if(this.state.replaceCalledFromOnLoad) {
        // if is called from onload, just ignore it. unset replaceCalledFromOnLoad for the next time 
        this.setState({replaceCalledFromOnLoad: false, onLoad: false});
      } else if (this.state.onLoad) {
        // do nothing. we are waiting for onloadDone to be called
      } else {
        const needOnload = !noOnloadRoutes.find(item => item === location.location.hash)
        if(needOnload) {
          this.setState({onLoad: true, replaceCalledFromOnLoad: false});
        } else {
          this.setState({onLoad: false, replaceCalledFromOnLoad: false});
        }
      }
    })
    if (window.electron) {
      window.electron.userLeave(()=>{
        window.electron.allWindowsClose()
      });
    } else {
      if(process.env.NODE_ENV !== "development" && (!sessionStorage || !sessionStorage.getItem("pAlertShown"))) {
        this.detectPrivateMode();
      }
    }
    window.locationTopBanner = TopTapImage
    window.locationBottomBanner = BottomTapImage
  }

  componentWillUnmount() {
    this.browserHistoryUnListen();
  }

  async detectPrivateMode() {
    try{
      const detectResult = await detectIncognito();
      if(detectResult.isPrivate) {
        toast.warn(`
          It looks like that you're currently using your browser in private/incognito mode. 
          please be aware that some essential features of our website may not work as intended in this mode.
          For the best experience, we recommend using the normal browsing mode. 
        `, {autoClose: false, closeOnClick: true})
      }
    } catch (err) {
      // no need to handle error
    } finally {
      if(sessionStorage && sessionStorage.setItem) {
        // set this anyway, so it is not possible to detect if user is in private mode seeing this variable
        sessionStorage.setItem("pAlertShown", true)
      }
    }
  }

  async checkTfjs() {
    try{
      const able = await window.electron.checkAbleTfjs();
      const exists = await window.electron.checkTfjsModelExists();
      const enabled = localStorage.getItem("tfjs_is_enable");

      if(able) {
        if(enabled == 'true' && !exists) {
          let modelUrl = "";
          if(localStorage.getItem("upload_url")) {
            modelUrl = localStorage.getItem("upload_url") + "id-card-tfjs-saved-model.zip"
          } else {
            modelUrl = process.env.REACT_APP_UPLOAD_URL + "id-card-tfjs-saved-model.zip"
          }
          await window.electron.downloadTfjsModel(modelUrl)
          await apiService.updateCardDetection({status: 1});
        } else if(enabled == 'true' && exists) {
          await apiService.updateCardDetection({status: 1});
        } else if(enabled != 'true' && exists) {
          localStorage.setItem("tfjs_is_enable", true);
          await apiService.updateCardDetection({status: 1});
        } else if(enabled != 'true' && !exists) {
          await apiService.updateCardDetection({status: 0});
        }
      } else {
        localStorage.removeItem("tfjs_is_enable");
        await apiService.updateCardDetection({status: 0});
      }
    } catch (err) {
      console.error(err)
    }
  }

  async checkDefaultScanner() {
    const currentDefaultScanner = localStorage.getItem("current_default_scanner")
    if(currentDefaultScanner) {
      window.electron.setDefaultScanner(JSON.parse(currentDefaultScanner))
    } else {
      window.electron.getDefaultScanner().then((defaultScanner) => {
        if(defaultScanner){
          localStorage.setItem("current_default_scanner", JSON.stringify(defaultScanner));
        }
      })
    }
  }

  checkLastVersionEveryMidnight(){
    const job = new CronJob('00 0/5 22,23,00-05 * * *',async function() {
      try{
        var token = tokenService.get();
        if(token){
          const checkVersionResult = await apiService.checkStatus()
          if(checkVersionResult?.data?.code===873283){
            const checkVersionData = checkVersionResult.data.data
            job.stop()
            window.electron.downloadLastVersion({...checkVersionData,msg:checkVersionResult.data.message})
          }
        }
      } catch (err) {
        console.error(err)
      }
    })
    job.start()
  }

  async checkLastVersion(){
    try{
      var token = tokenService.get();
      if(token && window.electron){
        const checkVersionResult = await apiService.checkStatus()
        if(checkVersionResult?.data?.code===873283){
          const checkVersionData = checkVersionResult.data.data
          if(checkVersionData.forceUpdate || window.confirm("We have made some changes to Virtual Sally. Do you want to download and install new version?")) {
            window.electron.downloadLastVersion({...checkVersionData,msg:checkVersionResult.data.message})
          }
        }
      }
    } catch (err) {
      console.error(err)
      toast.error(err.msg, {autoClose: 10000})
    }
  }

  socketListening(){
    if(!socket) return
    socket.on('msg', ({from, event, msg})=>{
      if(event === 'toast'){
        if(msg.code == 0) toast.error(from + ': ' + msg.text, {autoClose: 5000})
        else if(msg.code < 0) toast.error(from + ': ' + msg.text, {autoClose: 10000})
        else toast.warning(from + ': ' + msg.text, {autoClose: 5000})
      }
    })
    socket.on('kick',()=>{ // emit from api
      console.alert("kick")
      tokenService.remove() // remove token from cookie and session and localstorage
      // on clear data we need to relaunch the app for changes to take effect
      // call preload function sending relaunch to electron
      window.location.href="#/"
      if(window.electron) {
        window.electron.relaunch();
      }
    })
    socket.on('reloadRoom', () => {
      reloadSnoozed = false;
      this.reloadRooms()
    })
    //when another session trying to connect, socket server call this listener to avoid multiple session.
    // socket.on('alreadyConnected', () => {
    //   window.location.href='./#/already-connected'
    //   socket.disconnect()
    // })
  }

  reloadRooms() {
    const isReloadLock = localStorage.getItem("reload_lock");
    if(isReloadLock) {
      setTimeout(() => {
        this.reloadRooms()
      }, 3 * 1000);
    } else {
      toast.warning(
        <p className='mb-0 reloadRoomsWarning'>
          This page will reload in 10 seconds.&nbsp; 
          <a onClick={() => this.snoozeReloadRooms()} href="javascript: void(0)">Snooze</a>
        </p>, {autoClose: 10 * 1000})

      setTimeout(() => {
        if(reloadSnoozed) {
          return
        }
    
        if(window.electron) {
          window.electron.reload();
        } else {
          if(typeof window.location.replaceWithoutWarn === "function") {
            window.location.replaceWithoutWarn("#/")
          } else {
            window.location.replace("#/")
          }
        }
      }, 10 * 1000);
    }
  }

  snoozeReloadRooms(newRoom, newJitsi) {
    $(".reloadRoomsWarning").parent().hide()
    reloadSnoozed = true;
    toast.success("Snoozed for 5 minutes.")
    setTimeout(() => {
      reloadSnoozed = false
      this.reloadRooms(newRoom, newJitsi)
    }, 5 * 60 * 1000);
  }

  onLoadDone(newUrl) {
    // this.setState({...this.state, onLoad: false}, () => {
      // newUrl = newUrl.replace(/^\/#|^#|^\//, "");
      if(window.location.hash != newUrl) { 
        // Extra replacements will cause Doctor.js to render multiple times, leading to multiple Jitsi joins.
        this.setState({...this.state, replaceCalledFromOnLoad: true}, () => {
          window.location.replace(newUrl)
        })
      } else {
        this.setState({...this.state, onLoad: false});
      }
    // });
  }

  render() {
    return (
      <ErrorBoundary>
        <MyThemeProvider>
          <SocketContext.Provider value={socket}>
            {this.state.onLoad ? (
              <OnLoad onLoadDone={this.onLoadDone} />
            ) : (
              <Router history={browserHistory}>
                <Routes />
              </Router>
            )}
            <ToastContainer
              newestOnTop={true}
              closeOnClick={false}
              draggable={false}
            />
            <Confirm ref={this.confirmRef} />
          </SocketContext.Provider>
        </MyThemeProvider>
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = {
    setLocations,
    isSleep,
};

export default connect(null, mapDispatchToProps)(App);
