import React from 'react'
import { Switch, Route } from 'react-router-dom'
import IdleTimer from 'react-idle-timer';
import Kiosk from './Kiosk';
import { IdleTimeOutModal } from '../../Components/Modal/IdleModal';
import PropTypes from 'prop-types';
import PageConst from '../../constants/patientpage';

class Layout extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      timeout: 1000 * 30 * 1,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false
    }

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleTimeout = this.handleTimeout.bind(this)
  }

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    this.props.moveToPage(PageConst.WELCOME);
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleTimeout() {
    this.setState({ showModal: false })
    this.props.moveToPage(PageConst.WELCOME);
  }
  render() {
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout} />

        <div className="w-100">
          <Switch>
            <Route
              render={(props) => <Kiosk {...props} askForAssistance={this.props.askForAssistance} moveToPage={this.props.moveToPage}/>}
            />
          </Switch>
          <IdleTimeOutModal
            showModal={this.state.showModal}
            handleClose={this.handleClose}
            handleTimeout={this.handleTimeout}
          />
        </div>
      </>
    )
  }
}

Layout.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.func.isRequired
}

export default Layout