import React from "react";
import { styled } from '@mui/material/styles';
import { useEffect, useState,useRef } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import SpeakerOutlinedIcon from '@mui/icons-material/SpeakerOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import Storages from '../../constants/storages'
import AudioTest from '../../assets/sounds/alarm.mp3'
import AdminService from '../../services/api'
import {
    MenuItem,
    ListItem,
    ListItemText,
    ListItemIcon,
    Popper,
    ClickAwayListener,
    MenuList,
    Grow,
    Paper
  } from "@mui/material";
import { useSelector,useDispatch } from "react-redux";
import { selectMic  } from "../../redux/micSlice";
import { selectSpeaker  } from "../../redux/speaker";
import LinearProgress from '@mui/material/LinearProgress';
import { toast } from "react-toastify";

const PREFIX = 'AudioDevices';

const classes = {
    color: `${PREFIX}-color`
};

const Root = styled('div')(({
    theme: {palette,mode}
}) => ({
    [`& .${classes.color}`]: {
      color:palette.color[mode]
    }
}));

const audioContext = new AudioContext();

export default function AudioDevices({show,audiosID=[]}) {
    const anchorRefMic =useRef()
    const [selectedMic,setSelectedMic]=useState({})

    const anchorRefSp=useRef()
    const [selectedSpeaker,setSelectedSpeaker]=useState({})
    const microphones=useSelector((state)=>state.mics)
    const speakers=useSelector((state)=>state.speakers)
    const microNode = useRef();
    const [progress,setProgress]=useState(0)
    const dispatch = useDispatch();
    const [openMic,setOpenMic]=useState(false)
    const [openSp,setOpenSp]=useState(false)


    useEffect(()=>{
        if(!show) return null
        let audiooutput= speakers.find((s)=>s.selected===true)
        let audioinput= microphones.find((s)=>s.selected===true)
        setSelectedMic(audioinput)
        setSelectedSpeaker(audiooutput)
        if(document.getElementById("testSpeaker") && audiooutput )
            document.getElementById("testSpeaker").setSinkId(audiooutput.deviceId)
        if(audioinput) getMicrophoneAnalyserNode(audioinput.deviceId)
    },[show])


    useEffect(()=>{
        let mic=microphones.find((s)=>s.selected)
        if(selectedMic && mic && (mic.label !==selectedMic.label)){
            setSelectedMic(mic)
            getMicrophoneAnalyserNode(mic.deviceId)
        }
    },[microphones])

    useEffect(()=>{
        let sp=speakers.find((s)=>s.selected)
        if(selectedSpeaker && sp && (sp.label !==selectedSpeaker.label)){
            setSelectedSpeaker(sp)
            document.getElementById("testSpeaker").setSinkId(sp.deviceId)
        }
    },[speakers])
    
    const handleClickMenutItemMicro=(event)=>{
        setOpenMic(true)
    }
    const handleOnClickMicro=(ev,item)=>{
        setOpenMic(false)
        setSelectedMic(item)
        // localStorage.setItem(Storages.LOCAL_MIC_ID,item.deviceId)
        dispatch(selectMic({deviceId:item.deviceId}))
        // update Defaut Microphone Device system (location)
        let userRole=localStorage.getItem(Storages.LOCAL_ROLE)
        var label=item.label;
        getMicrophoneAnalyserNode(item.deviceId)
        AdminService.createActivityLog({
          tags: [userRole, 'microphone'],
          action: "update-device",
          subAction: "microphone",
          target: "__ME__",
          details: `${userRole} set microphone to ${label}`
        })
    }
    const handleCloseMenuMicro = () => {
        setOpenMic(false)
    };


    const getMicrophoneAnalyserNode = async (selectedId) => {
        try{
            const stream = await navigator.mediaDevices.getUserMedia({
              audio: {
                deviceId: {
                  exact: selectedId,
                },
              },
            });
  
            const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
            const analyserNode = audioContext.createAnalyser();
            mediaStreamAudioSourceNode.connect(analyserNode);
            
            const array = new Uint8Array(analyserNode.frequencyBinCount);
            microNode.current={analyserNode, pcmData: array}
            // check Audio Level Mic Node
            checkAudioLevelForMicro()

        } catch(err) {
          console.error(2002,err)
          err.message && toast.error(err.message)
        }
      }
  
      const checkAudioLevelForMicro = async () => {
        try{
            var micNode=microNode.current
            micNode.analyserNode.getByteTimeDomainData(micNode.pcmData);
            let level = timeDomainDataToAudioLevel(micNode.pcmData)
            setProgress((level*100).toFixed(0))
            window.requestAnimationFrame(checkAudioLevelForMicro);
        } catch (err) {
          console.error(2003,err)
        }
      }
  
      const timeDomainDataToAudioLevel = (samples) => {
        let maxVolume = 0;
        const length = samples.length;
        for (let i = 0; i < length; i++) {
          if (maxVolume < samples[i]) {
              maxVolume = samples[i];
          }
        }
        return parseFloat(((maxVolume - 127) / 128).toFixed(3));
      }

    // speaker
    const handleClickMenutItemSpeaker=(event)=>{
        setOpenSp(true)
    }
    
    const handleOnClickSpeaker=async(ev,item)=>{
        try {
            setOpenSp(false)
            setSelectedSpeaker(item)
            localStorage.setItem(Storages.LOCAL_SPEAKER_ID,item.deviceId)
            dispatch(selectSpeaker({deviceId:item.deviceId}))
            if(audiosID && audiosID[0]){
                audiosID.forEach(async(id)=>{
                    let el=document.getElementById(id)
                    if(el) await el.setSinkId(item.deviceId)
                })
            }
            await document.getElementById("testSpeaker").setSinkId(item.deviceId)
            if(window.JitsiMeetJS)window.JitsiMeetJS.mediaDevices.setAudioOutputDevice(item.deviceId) 
            // update Defaut Sound Device system (location)
            let userRole = localStorage.getItem(Storages.LOCAL_ROLE)
            var label = item.label;
            AdminService.createActivityLog({
              tags: [userRole, 'speaker'],
              action: "update-device",
              subAction: "speaker",
              target: "__ME__",
              details: userRole + " set speaker to " + label
            })
        } catch (error) {
            console.error(2004,error)
            toast.error(error.message,5000)
        }
   
    }

    const handleCloseMenuSpeaker = () => {
        setOpenSp(false)
    };

    const handleTestSpeaker=()=>{
        document.getElementById("testSpeaker").play();
    }
    return (
        <Root >
            <div className="my-2">
                <label>
                    Microphone
                </label>
                <ListItem 
                    ref={anchorRefMic}
                    button
                    aria-haspopup="true"
                    aria-controls="microphone"
                    onClick={handleClickMenutItemMicro}
                    >
                    <ListItemIcon>
                        <MicNoneOutlinedIcon  className={classes.color} />
                    </ListItemIcon>
                    <ListItemText className="text-truncate" title={(selectedMic && selectedMic.label) || ""} 
                        primary={
                            <span className={classes.color} >
                                {(selectedMic && selectedMic.label ) || "choose microphone"}
                            </span>
                        }/>
                        <ListItemIcon  edge="end">
                            <ArrowDropDownIcon className={classes.color}  />
                        </ListItemIcon>
                </ListItem>
                <Popper open={openMic}  anchorEl={anchorRefMic.current} role={undefined} transition disablePortal placement="bottom-start" style={{zIndex:1}}>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}>
                        <Paper >
                            <ClickAwayListener onClickAway={handleCloseMenuMicro}>
                            <MenuList autoFocusItem={openMic}   id="microphone">
                            {(microphones.length===0)?<MenuItem onClick={(e)=>{e.preventDefault();setOpenMic(false)}}>No microphone found</MenuItem>:
                                microphones.map((item, index) => 
                                <MenuItem 
                                onClick={(event) => handleOnClickMicro(event, item)}
                                selected={selectedMic && (item.deviceId === selectedMic.deviceId)} 
                                key={index} 
                                value={item && item.deviceId}>
                                    {item.label}
                                </MenuItem>
                            )}
                            </MenuList>
                            </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                </Popper>
                <LinearProgress style={{width:"90%", marginTop:"5px"}} variant="determinate" value={Number(progress) } />
            </div>
            <div className="my-2">
                <label>Speaker</label>    
                    <ListItem 
                        ref={anchorRefSp}
                        button
                        aria-haspopup="true"
                        aria-controls="speaker"
                        onClick={handleClickMenutItemSpeaker}
                        >
                        <ListItemIcon>
                            <SpeakerOutlinedIcon  className={classes.color} />
                        </ListItemIcon>
                        <ListItemText className="text-truncate" title={(selectedSpeaker && selectedSpeaker.label) || ""} 
                            primary={
                                <span className={classes.color}>
                                    {(selectedSpeaker && selectedSpeaker.label ) || "choose speaker"}
                                </span>
                            }/>
                        <ListItemIcon>
                            <ArrowDropDownIcon  className={classes.color}/>
                        </ListItemIcon>
                    </ListItem>
                    <Popper open={openSp}  anchorEl={anchorRefSp.current} role={undefined} transition disablePortal placement="bottom-start" style={{zIndex:1}}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                         }}
                        >
                        <Paper >
                            <ClickAwayListener onClickAway={handleCloseMenuSpeaker}>
                            <MenuList autoFocusItem={openSp}   id="speaker">
                            {(speakers.length===0)?<MenuItem onClick={(e)=>{e.preventDefault();setOpenSp(null)}}>No speaker found</MenuItem>:
                            speakers.map((item, index) => 
                            <MenuItem 
                            onClick={(event) => handleOnClickSpeaker(event, item)}
                            selected={selectedSpeaker && (item.deviceId === selectedSpeaker.deviceId)} 
                            key={index} 
                            value={item && item.deviceId}>
                                {item.label}
                            </MenuItem>
                            )}
                            </MenuList>
                            </ClickAwayListener>
                        </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
            <div className="my-2">
                <ListItem 
                    button
                    aria-haspopup="true"
                    aria-controls="test-speaker"
                    onClick={handleTestSpeaker}
                    >
                    <ListItemIcon>
                        <VolumeUpOutlinedIcon  className={classes.color}  />
                        <audio id="testSpeaker" src={AudioTest}/>
                    </ListItemIcon>
                    <ListItemText  primary={
                        <span className={classes.color}>Test speaker</span>
                    }/>
                </ListItem> 
            </div>
        </Root>
    );
}
