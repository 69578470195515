import React, { useState, useEffect, useContext, useRef } from 'react';
import {  withRouter } from 'react-router-dom';
import { IdleEmojiTimeOutModal } from '../../Components/Modal/IdleEmojiModal';
import AdminService from '../../services/api';
import Draggable from 'react-draggable';
import Patient from './Patient';
import './style.css';
import PageConst from '../../constants/patientpage';
import Constant from '../../constants/constant';
import Welcome from './Welcome';
import Appointment from './Appointment';
import SelfCheck from './SelfCheck';
import Layout from './Layout';
import Str from '../../constants/string';
import {  useSelector, useDispatch } from 'react-redux';
import { isSleep } from '../../redux/sleepLocation';
import { SocketContext } from '../../context/socket';
import PaymentRequestedModal from '../../Components/Modal/PaymentRequested';
import { toast } from 'react-toastify';
import ScanModal from '../../Components/Modal/Scan';
import CardDetection from '../../Components/Location/CardDetection';
import VersionComponent from '../../Version'
import Modal from 'react-bootstrap/Modal';
import Color from '../../constants/usercolor';
import Connection from '../../Components/User/Connection';
import ReactLoading from 'react-loading';
import * as i18n from "../../services/i18n"
import {HiddenMenu} from  '../../Components/Location/HiddenMenu'
import AudioTest from '../../assets/sounds/alarm.mp3'
import { setScreens } from '../../redux/screen';
import Screens from './Screens';

const MainLocationPage = (props) => {
    const [pageIndex, setPageIndex] = useState(PageConst.WELCOME);
    const [reception, setReception] = useState("")
    const [isShowCall, setIsShowCall] = useState(false);
    const [showEmojiModal, setShowEmojiModal] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const showCallValue = React.useRef(false);
    const nodeRef = React.useRef(null);
    const pageIndexRef = React.useRef(0);
    const sleepMode = useSelector((state) => state.isSleep)
    const connections = useSelector((state) => state.connections)
    const screens = useSelector((state) => state.screens)
    const socket = useContext(SocketContext);
    const [openScanModal, setOpenScanModal] = useState(false);
    const showKioskFrameRef=useRef(false)
    const [showKioskFrame, _setShowKioskFrame] = useState(false);
    const setShowKioskFrame =(x)=>{
      showKioskFrameRef.current=x
      _setShowKioskFrame(x)
    }
    const [showKioskAlert, setShowKioskAlert] = useState(false);
    const [kioskTimeout, setKioskTimeout] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const callToken=useRef()
    const dispatch = useDispatch()
    const screenRef=useRef()
    useEffect(() => {
        localStorage.removeItem("reload_lock")

        i18n.init();
        getListScreens()
        i18n.getLangsDicts().then((langsDictRes) => {
          if(langsDictRes.code !== 0) {
            toast.error(langsDictRes.msg, {autoClose: 7000})
          }
          setIsLoading(false)
        })
        socket.on('reload',()=>{
          console.alert(1101, "Reload")
          if(window.electron) {
            window.electron.reload();
          } else {
            window.location.replace("#/")
          }
        })
        socket.on("paymentRequested", (data) => {
          setPaymentDetails({
            show: true,
            amount: data.amount,
            desc: data.desc,
            // name: details.name,
            canceled: data.canceled,
          })
        }); 
        socket.on("paymentCanceled", (data) => {
          setPaymentDetails({
            show: false
          })
          toast.error(window.i18n.getString("paymentCanceled"), {autoClose: 10000})
        })
        socket.on("paymentResult", (data) => {
          setPaymentDetails({
            show: false
          })
          if (data.code === 0) {
            toast.success(window.i18n.getString("paymentThanks"), {autoClose: 5000})
          } else if(data.code > 0) {
            toast.warning(window.strings[data.data?.errCode] || data.message, {autoClose: 10000})
          } else {
            console.error(1198, data)
            toast.error(window.strings[data.data?.errCode] || data.message, {autoClose: 10000})
          }
        })

        socket.on("printText", (data) => {
          if(data && data.text) {
            toast.success(window.i18n.getString("printing"), {autoClose: 5000})
            if (window.electron) {
              window.electron.textForPrint(data.text)
            }
          }
        })

        socket.on('msg',({event, msg, from})=>{
          if(event === 'print' && msg.file){
            toast.success(window.i18n.getString("printing"), {autoClose: 5000})
            if (window.electron) window.electron.fileForPrint(msg.file)
          } else if(event ==='unSleep'){
            console.alert(1102, "UnSleep")
            if(sleepMode) dispatch(isSleep(false))
            if(window.electron) {
              window.electron.reload();
            } else {
              window.location.replace("#/")
            }
          } else if(event === 'capture-camera-image'){
            captureCameraImage(from)
          } else if (event === "play-test-sound") {
            document.getElementById("testSpeaker").play();
          }
        })
        socket.on('sleep',({sleep})=>{
          if(sleep !=sleepMode)
            dispatch(isSleep(sleep))
        })
        socket.on('update-screen',async({topBanner,bottomBanner})=>{
          toast.info("Screen updated from admin.")
          if(topBanner && bottomBanner) {
            if (topBanner.startsWith("http://") || topBanner.startsWith("https://")) {
              window.locationTopBanner = topBanner
            } else {
              window.locationTopBanner = process.env.REACT_APP_UPLOAD_URL + topBanner
            }
            if (bottomBanner.startsWith("http://") || bottomBanner.startsWith("https://")) {
              window.locationBottomBanner = bottomBanner
            } else {
              window.locationBottomBanner = process.env.REACT_APP_UPLOAD_URL + bottomBanner
            }
          }// no else. we set it in App to the default banners, leave it as
          getListScreens()
        })

        if (window.electron) {
          window.electron.toggleDarkMode('light')
          window.electron.kioskClosed(() => {
            setShowKioskFrame(false)
            if(!isShowCall){
              pageIndexRef.current = PageConst.WELCOME
              if(!showKioskFrameRef.current)   setPageIndex(pageIndexRef.current);
              screenRef.current && screenRef.current.BackToMain()
            }

          })
          window.electron.showKioskAlert(() => {
            setShowKioskAlert(true)
            setKioskTimeout(10)
          })
          window.electron.hideKioskAlert(() => {
            setShowKioskAlert(false)
            setKioskTimeout(0)
          })
          let vol=localStorage.getItem('volume')
          if(vol) window.electron.setVolume(vol)
        }
        return () => {
          socket.off("reload");
          socket.off("paymentRequested");
          socket.off("paymentCanceled");
          socket.off("paymentResult");
          socket.off("printText")
          socket.off("msg");
          socket.off("unSleep");
          socket.off("sleep");
          socket.off("update-screen");
        };
    }, []);
    
    const getListScreens=async()=>{
      try {
        var res=await AdminService.getListScreens()
        if(res.data.code===0){
          let data=res.data.data.screens || []
          dispatch(setScreens(data))
        } else {
          console.error("6651", "getListScreens response", res.data)
          toast.error(res.data.msg || "Internal server error", {autoClose: 10000})
        }
      } catch (error) {
        console.error(6715, error)
        toast.error(error.message || "Internal server error", {autoClose: 10000})
      }
    }

    useEffect(() => {
      if(showKioskAlert) {
        if(kioskTimeout > 0) {
          setTimeout(() => {
            setKioskTimeout((prevVal) => --prevVal);
          }, 1000);
        } else {
          onKioskAlertClick("no")
        }
      }
    }, [kioskTimeout])

    function setShowCallState(flag) {
        setIsShowCall(flag);
        showCallValue.current = flag;
        if(flag) {
          // dont go back to welcome page until the call is finished. Fix language change in the middle of the call
          clearInterval(window.goBackToWelcomeTimeout)
        } else {
          if(!showKioskFrameRef.current) moveToPage(PageConst.WELCOME)
        }
    }

    function updateUserStatus(status) {
      console.alert(1106, "updateUserStatus, status:", status)

        AdminService.updateLocationStatus({status})
            .then(
                response => {
                    if (response.data.code !== 200) {
                        console.alert(1197, response.data)
                        toast.error(window.i18n.getString("serverError"));
                    }
                },
                error => {
                  console.error(1195, error)
                    toast.error(window.i18n.getString("serverError"));
                }
            );
    }

    var biRef = {
        inComingCallFromReception: inComingCallFromReception,
        updateShowKioskFrame:(x)=>setShowKioskFrame(x)
        
    }

    /**
     * Function that come from reception to location.
     */
    function inComingCallFromReception() {
      console.alert(1107, "inComingCallFromReception")

        localStorage.setItem("reload_lock", 1)
        updateUserStatus(Constant.CALL);
        if (showCallValue.current === false) {
            biRef.updateCallTag(Str.STR_RECEPTION);
        }
        setShowCallState(true);
    }

    function inComingCall(strType,color) {
      console.alert(1109, "inComingCall, strType:", strType)

        localStorage.setItem("reload_lock", 1)
        if (isShowCall === true) {
            return;
        }

        updateUserStatus(Constant.INCOMING);
        setShowCallState(true);
        biRef.inComingCall(strType,color);
    }

    const callExit = (isExitOnCall, reception, token, status) => {
      console.alert(1110, "callExit, isExitOnCall:", isExitOnCall, "reception:", reception)

        updateUserStatus(Constant.JOIN);
        setShowCallState(false);
        setReception(reception)

        var data = {};
        data['type'] = Constant.LOCATION;
        data['token'] = token;
        data['status'] = status;
        callToken.current=token
        AdminService.currentCallEnd(data)
            .then(
                response => {
                    if (response.data.code !== 200) {
                        console.alert(1194, response.data.msg)
                        toast.error(window.i18n.getString("serverError"));
                    }
                },
                error => {
                  console.error(1193, error)
                  toast.error(window.i18n.getString("serverError"));
                }
            );

        //To move welcome page when video call exit
        if (isExitOnCall) {
            handleCloseScanModal()
            if(status !== "Transfer") {
              // No review when transferring the call to a new receptions
              setShowEmojiModal(true)
            }
            if(!showKioskFrameRef.current) pageIndexRef.current = PageConst.WELCOME
        } else {
          localStorage.removeItem("reload_lock")
        }
    }

    const handleShowCallDialog = (strType, withKioskFrame,color) => {
      if(withKioskFrame){
        showKioskView(strType, withKioskFrame,color)
        return
      }
      if(strType === Str.LAUNCH_WEB) {
        setShowKioskFrame(true)
        biRef.updateCallTag && biRef.updateCallTag(strType,color)
        return
      }
      console.alert(1115, "handleShowCallDialog, strType:", strType, "withKioskFrame:", withKioskFrame)
        let check=biRef.checkRoom()
        if(!check) return toast.warning("The connection has failed. We apologize for the inconvenience. Please try again later.",3000)
        inComingCall(strType,color);
    }

    const moveToPage = (index) => {
        setPageIndex(index);
    }

    const closeEmojiModal = (index) => {
        setShowEmojiModal(false)
        if(!showKioskFrameRef.current)
        setPageIndex(pageIndexRef.current);
        callToken.current=null
        localStorage.removeItem("reload_lock")
    }

    const updateActivity=async()=>{
      try{
        await AdminService.updateLastActivity(props.match.params.username)
        if(window.electron) {
          window.electron.reload();
        } else {
          window.location.replace("#/")
        }
      } catch (err) {
        console.error(1186, err)
      }
    }

    const handleTimeout = () => {
      setShowEmojiModal(false)
      localStorage.removeItem("reload_lock")
    }

    const handleCloseScanModal=()=>{
      setOpenScanModal(false)
    }    
    const handleOpenScanModal=()=>{
      setOpenScanModal(true)
    }

    const onKioskAlertClick = (action) => {
      if(window.electron) {
        window.electron.onKioskAlertClick(action)
      }
    }

    const captureCameraImage = async (fromReception) => {
      try{
        const video = document.getElementById(`mainVideo`);
        let snapshot = document.createElement('canvas');
        snapshot.width = video.clientWidth;
        snapshot.height = video.clientHeight;
        let snapshotCTX = snapshot.getContext('2d')
        snapshotCTX.drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
        // let snapshotDataURL = snapshot.toDataURL('image/jpeg');
        snapshot.toBlob(async (blob) => {
          const batchID = localStorage.getItem("lucas_therapies_logined_username") + "-" + new Date().getTime();
          const axiosRes = await AdminService.uploadScanResult({
            file: blob, batchID, fromReception,
          })
          if(axiosRes.data.code === 0) {
            await AdminService.sendMessage({
              to: fromReception,
              event: "capture-image-res",
              msg: {code: 0, data: {batchID}}
            })
          } else {
            await AdminService.sendMessage({
              to: fromReception,
              event: "capture-image-res",
              msg: {code: axiosRes.data.code, msg: "Error while capturing image: " + axiosRes.data.msg}
            })
          }
        })
      } catch (err) {
        console.error(err)
        await AdminService.sendMessage({
          to: fromReception,
          event: "capture-image-res",
          msg: {code: 7, msg: "Error while capturing image: " + err.message}
        })
      }
    }

    const showKioskView=async(strType, withKioskFrame,color)=>{
      if(withKioskFrame){
        window.electron.showKioskView(localStorage.getItem("kiosk_url"), localStorage.getItem("kiosk_timeout"))
        setShowKioskFrame(true)
        biRef.updateCallTag(strType,color)
      }
    }
    const askForAssistance=async ()=>{
      let check=biRef.checkRoom()
      if(!check) return toast.warning("The connection has failed. We apologize for the inconvenience. Please try again later.",3000)
      inComingCall(Str.ASSISTANCE_KIOSK);
    }

    return (
      <>
      {isLoading ? 
      <div className='root'>
        <HiddenMenu />
        <img className='top_tap' src={window.locationTopBanner} alt="top_tap" />
        <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
        <ReactLoading className="m-auto" type={"spin"} color={"#0085d2"} /> 
      </div>: 
      <div>
        {paymentDetails && <PaymentRequestedModal 
          showModal={paymentDetails.show} 
          amount={paymentDetails.amount} 
          desc={paymentDetails.desc} 
          handleClose={() => {
            setPaymentDetails({
              show: false
            })
          }}
          // name={paymentDetails.name}
          msg={paymentDetails.msg}
        />}
        <Modal show={showKioskAlert} size={'md'} centered>
          <Modal.Body>
            <div className='p-3'>
              <label class="text-center pt-2 pb-3">
                <h3>{window.i18n.getString("kioskTimeoutQuestion")}</h3>
                {window.i18n.getString("kioskTimeout1", {totalSeconds: localStorage.getItem("kiosk_timeout")})}<br/>
                ({window.i18n.getString("kioskTimeout2", {remainingSeconds: kioskTimeout})})
              </label>
              <div className='row'>
                <div className='color_btn' onClick={() => onKioskAlertClick("yes")} 
                  style={{backgroundColor: Color.GREEN_BTN_COLOR, width: "130px", height: "50px", cursor: "pointer"}}>
                  <label className='m-0' style={{color: "white", fontSize: "20px", fontFamily: "Medium"}}>{window.i18n.getString("yes")}</label>
                </div>
                <div className='color_btn' onClick={() => onKioskAlertClick("no")}
                  style={{backgroundColor: Color.YELLOW_BTN_COLOR, width: "130px", height: "50px", cursor: "pointer"}} >
                  <label className='m-0' style={{color: "white", fontSize: "20px", fontFamily: "Medium"}}>{window.i18n.getString("no")}</label>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {sleepMode && connections.internet && connections.server ?
          <>
            <div className="d-flex justify-content-center align-items-center" 
              style={{zIndex: 10, position: "absolute", top: 0, left: 0, backgroundColor: "#aaaaaaaa", width: "100%", height: "100%"}} 
              onTouchEnd={updateActivity} onClick={updateActivity}>
              <h1 style={{backgroundColor: "white"}}><b>&nbsp; {window.i18n.getString("sleep")} &nbsp;</b></h1>
            </div>
          </>
        : ""}
        <div className='root' style={{position: "absolute", top: 0, left: 0}}>                  
            <Draggable bounds="parent" nodeRef={nodeRef} cancel=".drag_cancel">
            {/* style={props.showKioskFrame ? {width: "50%", maxWidth: "50%"} : {}} */}
              <div ref={nodeRef} 
                className={"box "+  ( showKioskFrame ?" draggable-kiosk-mode-background ":" draggable-room-background ")+ (isShowCall ? " show" : " hide")}
                style={{ position: "absolute", top: showKioskFrame?"2vh":"5vh", right: showKioskFrame?"2vw":"5vw", zIndex: 3 }}>
                <Patient 
                  callExit={callExit} 
                  biRef={biRef} 
                  isShowCall={isShowCall} 
                  showKioskFrame={showKioskFrame}
                />
              </div>
            </Draggable>
            {
              !connections.internet ?
                <Connection msg={window.i18n.getString("noInternetConnection")}/>
              : !connections.server ?
                <Connection msg={window.i18n.getString("upgradingOurServices")}/>
              :
              (screens && screens[0])? 
                <Screens handleShowCallDialog={handleShowCallDialog} 
                  isCalling={isShowCall} askForAssistance={askForAssistance}
                  ref={screenRef}
                  />
              :
                <>
                  {
                    pageIndex === PageConst.WELCOME ?
                        <Welcome handleShowCallDialog={handleShowCallDialog} 
                          moveToPage={moveToPage} isCalling={isShowCall} />
                        :
                        null
                  }
                  {
                      pageIndex === PageConst.APPOINTMENT ?
                          <Appointment handleShowCallDialog={handleShowCallDialog} 
                            moveToPage={moveToPage} isCalling={isShowCall} />
                          :
                          null
                  }
                  {
                      pageIndex === PageConst.SELFCHECK ?
                          <SelfCheck handleShowCallDialog={handleShowCallDialog} 
                            moveToPage={moveToPage} isCalling={isShowCall} />
                          :
                          null
                  }
                  {
                      pageIndex === PageConst.KIOSK ?
                          <Layout askForAssistance={askForAssistance} 
                            moveToPage={moveToPage} isCalling={isShowCall} />
                          :
                          null
                  }
                </>
            }
            
            <IdleEmojiTimeOutModal
                showModal={showEmojiModal}
                handleClose={closeEmojiModal}
                handleTimeout={handleTimeout}
            />
            <ScanModal 
              showModal={openScanModal} 
              handleOpen={handleOpenScanModal}
              handleClose={handleCloseScanModal} 
              myUsername={props.match.params.username}
            />

        </div>
        <CardDetection/>
        <VersionComponent/>
        <audio id="testSpeaker" src={AudioTest}/>
      </div>}
      </>
    )
}

MainLocationPage.prototype = {

}

export default withRouter(MainLocationPage);
