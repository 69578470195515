import React, { useState , useContext } from 'react'
import LucasLogo from '../../assets/images/virtualsally_logo.png';
import {NumPadModal} from '../Modal/NumPad';
import { AdministrationModal } from '../Modal/AdministrationModal';
import AdminService from '../../services/api';
import Storages from '../../constants/storages';
import { SocketContext } from '../../context/socket';
import { toast } from 'react-toastify';
import tokenService from '../../services/tokenService';
export const HiddenMenu = ()=>{
    const [showNumPad,setShowNumPad]=useState(false)
    const [showMenu,setShowMenu]=useState(false)
    const [modalTimeout,setModalTimeout] = useState()
    const [limitedAccess,setLimitedAccess] = useState(false)
    const socket = useContext(SocketContext)


    const handleOpen=()=>{
      if(process.env.NODE_ENV === "development") {
        setShowMenu(true)
        return
      }
      setShowNumPad(true)
      clearTimeout(modalTimeout)
      let t=setTimeout(()=>setShowNumPad(false),5000)
      setModalTimeout(t) // save timeout for clear before set again
    }
    const updateTimeOut=()=>{ // update timeout after click in numpad
        clearTimeout(modalTimeout)
        let t=setTimeout(()=>setShowNumPad(false),5000)
        setModalTimeout(t) 
    }
    const handleCheckNumpad=(code)=>{
        // The code to exit the program would be related to the current date and time. For instance, if the current date is July 12 and it is before noon the code would be 120601 
        // This would be the date 12 month 06 and then 01 for am (before noon) or 02 for pm (after noon) 
        // The code should always be six digits, so in the case of January 5 after noon, the code would be 050102 
        setShowNumPad(false)
        if(code && code.length!=6) return
        const userRole = localStorage.getItem(Storages.LOCAL_ROLE) 
        let token = tokenService.get();
        var isLoc=(userRole === "location" && token)?true:false
        if(
          localStorage.getItem("admin_panel_code") && 
          code == localStorage.getItem("admin_panel_code") && 
          isLoc) {
            setLimitedAccess(true)
            setShowMenu(true)
            return;
        }
        let now=new Date()
        let month=now.getMonth()+1
        if(Number(code.slice(0,2))!=now.getDate()) return
        if(Number(code.slice(2,4))!=month) return
        let h=Number(code.slice(4,6))
        if((now.getHours()<12 && h!=1) || (now.getHours()>=12 && h!=2) ) return
        setLimitedAccess(false)
        setShowMenu(true)
    }

    const handleExitApp =()=>{
      if(window.electron) {
        window.electron.closeApp();
      } else {
        window.close();
      }
    }

    const handleClearData=async()=>{
      window.confirmAsync.show(
        <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>Confirm</h6>, 
        <span style={{fontSize: "1.05rem", marginBottom: "0px"}}>
          Are you sure you want to clear all data?
        </span>, 
        [
          { value: 1, color: "primary", text: "Yes", close: 1 },
          { value: 0, color: "default", text: "No", close: 1 },
        ]
      ).then(async (value)=>{
        if(value === 1){
          try{
            handleClose()
            var username=localStorage.getItem(Storages.LOCAL_LOGINED_USER)
            if(socket) socket.disconnect()// clear auth token
            if(username) await AdminService.logout({username})
            localStorage.clear()
            // on clear data we need to relaunch the app for changes to take effect
            // call preload function sending relaunch to electron
            if (window.electron) { // electron mode
              window.electron.setAlwaysOnTop(false)
              localStorage.setItem(Storages.LOCAL_ONTOP_MODE,false)
              window.electron.relaunch()
            }
            else window.location.replace("#/") // browser mode
          } catch (err) {
            console.error(err)
            toast.error(err.message)
          }
        }
      })

      // if(window.confirm("Are you sure you want to clear all data?")){

      // }
    }

    const handleClose=()=>{
        setShowNumPad(false)
        setShowMenu(false)
    }
    const handleReload=()=>{
      try {
        AdminService.createActivityLog({
          tags:['reload', 'location'],
          action: "reload",
          subAction: "by-location",
          target: "__ME__",
          details: "Location reloaded vs."
        }).catch(err => {});
        if(window.electron) {
          window.electron.reload();
        } else {
          window.location.replace("#/")
        }
      } catch (error) {
        toast.error(error.message)
      }
    }
    return (
        <div>
            <img className='lucas_logo' src={LucasLogo} alt="lucas_logo" onClick={handleOpen}/>
            <NumPadModal showModal={showNumPad} handleClose={handleClose} handleCheck={handleCheckNumpad} updateTimeOut={updateTimeOut}/>
            <AdministrationModal 
              limitedAccess={limitedAccess}
              showModal={showMenu}
              handleClose={handleClose} 
              handleExit={handleExitApp} 
              handleClearData={handleClearData} 
              handleReload={handleReload}
            />
        </div>
    )
}
