import { createSlice } from '@reduxjs/toolkit';

export const ReceptionSidebar=createSlice({
  name:"receptionSidebar",
  initialState: true,
  reducers:{
    setReceptionSidebar:(state, action)=>{
      state=action.payload
      return state
    },
    toggleReceptionSidebar:(state)=>{
      return !state
    }
  }
})

export const { setReceptionSidebar, toggleReceptionSidebar } = ReceptionSidebar.actions

export default ReceptionSidebar.reducer