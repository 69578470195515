const str = {
    STR_CALL_TYPE: "CallType",
    STR_CALL_TYPE_COLOR: "CallTypeColor",
    STR_EMPUTY: "",
    STR_RECEPTION: "Reception Help",
    STR_NORMAL: "General Help",
    STR_NEW_PATIENT: "New Patient",
    STR_NO_APPOINTMENT: "No Appointment",
    STR_APP_TITLE: "Mill Mountain Technologies | Virtual Sally",
    STR_SELF_CHECK: "Self Check-in",
    STR_OFF_LINE: "Offline",
    STR_AWAY: "Away",
    STR_ON_LINE: "Online",
    STR_ON_CALL: "On Call",
    STR_SEND_MESSAGE: "Send message successfully!",
    STR_INPUT_USERNAME: "Please enter your username or email",
    STR_INPUT_PASSWORD: "Please enter your password",
    STR_EMAIL_INVALID: "Invalid email",
    STR_SERVER_ACCESS_ERROR: "Server connection error",
    STR_AUDIO: "audio",
    STR_VIDEO: "video",
    STR_INPUT: "input",
    STR_OUTPUT: "output",
    STR_AUDIO_INPUT: "audioinput",
    STR_AUDIO_OUTPUT: "audiooutput",
    STR_VIDEO_INPUT: "videoinput",
    STR_CALL_CONNECTING: "Connecting...",
    STR_CALL_CONNECTED: "Connected",
    STR_INITIAL_EVAL:"Initial Eval",
    STR_CHECK_IN:"Check In",
    KIOSK:"Kiosk",
    ASSISTANCE_KIOSK:"Assistance Kiosk",
    LAUNCH_WEB:"Launch website"
}

module.exports = str
