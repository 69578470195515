import React, { useEffect, useState, useRef,useMemo } from 'react';
import { styled } from '@mui/material/styles';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import ListLocations from './ListLocations';
import Str from '../../constants/string';
import Constant from '../../constants/constant';
import { ButtonBase ,Grid} from '@mui/material';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import VersionComponent from '../../Version'
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import AlarmAudio from "../../assets/sounds/alarm.mp3";
import * as $ from 'jquery';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';
import { setReceptionSidebar } from '../../redux/receptionSidebar';

const Root = styled('div')(({
  theme: {palette,mode}
}) => ({
    display: 'flex',
    width: '100%',
    height: "92vh",
    paddingTop: "2vh",
    alignItems: 'center',
    flexDirection: 'column',
    position:"relative",
    justifyContent:"center",
    backgroundColor:palette.background[mode],
    overflowX:'hidden',
    ['& .text-name']:{
      color:palette.color[mode],
    }
}))

const ToggleLocationSidebarButton = styled(ButtonBase)(({
  theme: {palette,mode}
}) => ({
  position: "fixed",
  display: "block",
  zIndex: "2",
  marginLeft: "-30px",
  outline: "none",
  height: "2.8em",
  borderRadius: "20px 0px 0px 20px",
  marginTop: "46vh",
  backgroundColor: palette.color[mode],
  '& svg': {
    color: palette.background[mode],
  },
}))

const LocationArea = React.memo((props) => {
  const {
    callState,
    remoteLocations,
    allLocationData,
    localUserData,
    handleItemSleep,
    locationEngaged,
    micro,
    camera,
    monitorCallStatus,
    monitorCallHold,
    handleDeviceManagement,
    handleOpenMessagingBoard,
    captureLocationCameraImage
  } = props;
  const dispatch = useDispatch();
  const [locationsBar, setLocationsBar] = useState(true);
  const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
  const receptionSidebar = useSelector((state) => state.receptionSidebar)
  const [sizeLocationItem, setSizethLocationItem] = useState(["230px", "130px"]); // width, height
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const [isCallOnHold, setIsCallOnHold] = useState(false);
  const [callHoldTimer, setCallHoldTimer] = useState(0);
  const [windowResizedToggle, setWindowResizedToggle] = useState(false);
  const refLocationArea = useRef();
  const callAlarmInterval = useRef(null);
  const locationAreaRef=useRef()
  const debounceTimeoutRef = useRef(null);
  const lastWindowWidth = useRef(0);
  const ListLocationsElement = useMemo(
    () => ListLocations,
    [
      callState,
      sizeLocationItem[0],
      sizeLocationItem[1],
      remoteLocations,
      allLocationData,
      localUserData,
      locationEngaged,
      showSecondaryLocations, 
      locationAreaRef,
    ]
  );

  useEffect(() => {
    if($("#locationVideo")[0]) {      
      $("#locationVideo")[0].addEventListener("pause", videoPaused)
      $("#locationVideo")[0].addEventListener("play", videoPlayed)
    }

    window.addEventListener('resize', windowResized);

    lastWindowWidth.current = window.innerWidth

    return () => {
      if($("#locationVideo")[0]) {
        $("#locationVideo")[0].removeEventListener("pause", videoPaused)
        $("#locationVideo")[0].removeEventListener("play", videoPlayed)
      }

      clearInterval(callAlarmInterval.current)
      callAlarmInterval.current = null;
      if(document.getElementById("alarmAudio")) {
        document.getElementById("alarmAudio").pause();
      }

      window.removeEventListener('resize', windowResized);
    }
  }, [])

  const videoPaused = () => {
    setIsVideoPaused(true)
    if(callState) {
      console.alert(5901, "Video paused during a call with", locationEngaged)
    }
  }

  const videoPlayed = () => {
    setIsVideoPaused(false)
  }

  useEffect(() => {
    if(callState) {
      clearInterval(callAlarmInterval.current)
      callAlarmInterval.current = null;
      document.getElementById("alarmAudio").pause();
      return;
    }

    let ringCallAlarm = false;
    for(const remoteLocation of remoteLocations) {
      if(remoteLocation.status === Constant.INCOMING && !remoteLocation.inCall && remoteLocation.calltag !== Str.KIOSK) {
        ringCallAlarm = true;
        break;
      }
    }

    if(ringCallAlarm) {
      if(!callAlarmInterval.current) {
        document.getElementById("alarmAudio").play();
        callAlarmInterval.current = setInterval(() => {
          document.getElementById("alarmAudio").play();
        }, 4000)
      }
    } else {
      clearInterval(callAlarmInterval.current)
      callAlarmInterval.current = null;
      document.getElementById("alarmAudio").pause();
    }
  }, [remoteLocations, callState])

  useEffect(() => {
    if(remoteLocations[0] && locationEngaged) {
      const found = remoteLocations.find(item => item.username === locationEngaged && item.status === Constant.HOLD)
      if(found) {
        setIsCallOnHold(true)
        setCallHoldTimer(found.holdTimer)
      } else {
        setIsCallOnHold(false)
        setCallHoldTimer(0)
      }
    }
  }, [remoteLocations, locationEngaged])

  // calculate width and height of location item.
  useEffect(()=>{
    setDimension()
  },[allLocationData,locationEngaged,showSecondaryLocations,monitorCallStatus])

  // calculate width and height of location item after completely open/close sidebar.
  useEffect(()=>{
    const timeout=setTimeout(()=>setDimension(),600)
    return()=>{
      clearTimeout(timeout)
    }
  }, [receptionSidebar])

  useEffect(()=>{
    setTimeout(() => {      
      checkAndPlaceReceptionVideo()
    }, 600); // wait for sidebar to close completely
  }, [receptionSidebar, callState])

  useEffect(() => {
    if (locationEngaged) {
      setLocationsBar(false)
    } 
  }, [locationEngaged])

  useEffect(() => {
    if (monitorCallStatus) {
      setLocationsBar(false)
    } 
  }, [monitorCallStatus])

  useEffect(() => {
    setDimension();
    checkAndPlaceReceptionVideo()
  }, [windowResizedToggle])

  const checkAndPlaceReceptionVideo = () => {
    const locationAreaRoot = document.getElementById("locationAreaRoot")
    if(!receptionSidebar && callState && locationAreaRoot.clientWidth > 500) {
      const mainRVideo = document.getElementById("mainRVideo")
      mainRVideo.classList.remove("reception-self-video")
      mainRVideo.classList.add("reception-self-video-on-top")

      const locationVideo = document.getElementById("locationVideo")

      const actualVideoHeightPx = locationVideo.videoHeight * locationVideo.clientWidth / locationVideo.videoWidth
      const videoFromTop = (locationAreaRoot.clientHeight - actualVideoHeightPx) / 2

      if(videoFromTop > 0) {
        mainRVideo.style.top = videoFromTop + 40 + "px"
      } else {
        mainRVideo.style.top = "60px"
      }

    } else {
      const mainRVideo = document.getElementById("mainRVideo")
      mainRVideo.classList.remove("reception-self-video-on-top")
      mainRVideo.classList.add("reception-self-video")
    }
  }

  const windowResized = () => {
    clearTimeout(debounceTimeoutRef.current)
    debounceTimeoutRef.current = setTimeout(async () => { 
      if(window.innerWidth < 1000 && lastWindowWidth.current > 1000) {
        dispatch(setReceptionSidebar(false))
      }
      if(window.innerWidth > 1000 && lastWindowWidth.current < 1000) {
        dispatch(setReceptionSidebar(true))
      }
      lastWindowWidth.current = window.innerWidth
      setWindowResizedToggle(prev => !prev)
    }, 100);
  }

  const toggleLocationsBar=()=>{
    setLocationsBar((prevState)=>!prevState)
  }

  const setDimension=()=>{
    var length=allLocationData?.length
    if(!showSecondaryLocations) {
      length = allLocationData.filter(item => item.isPrimary)?.length || 0
    }
    if(length==0) return

    if(!refLocationArea.current) {
      return
    }
    let sqrt=Math.sqrt(length)
    let round=Math.ceil(sqrt)
    let {width: containerWidth, height: containerHeight} = refLocationArea.current.getBoundingClientRect()
    // each thumbnail have 2px margin
    let width, height;
    if(length > 3){
      let containerUsableWidth = containerWidth - (round * 4) - 1
      let containerUsableHeight = containerHeight - (round * 4) -1
      width = containerUsableWidth/round
      if(width < 160) {
        // 160 is the minimum width of each thumbnail set in styles for location_box_root
        while(round * 160 > containerUsableWidth) {
          round--;
        }
        containerUsableWidth = containerWidth - (round * 4) - 1
        width=containerUsableWidth/round
      }
      if(length> round*(round-1))
        height=containerUsableHeight/round
      else height=containerUsableHeight/(round-1)
    } else {
      let containerUsableWidth = containerWidth - (length * 4) - 1
      let containerUsableHeight = containerHeight - (length * 4) -1
      width = containerUsableWidth/length
      if(width < 160) {
        width = containerUsableWidth
        height = containerUsableHeight/3
      } else {
        height = containerUsableHeight
      }
    }
    // if(width < 160) {
    //   width = 160;
    // }
    setSizethLocationItem([
      width,height
    ])
  }

  function secondsToHms(seconds) {
    // Create a Date object with the seconds converted to milliseconds
    const date = new Date(seconds * 1000);
    
    // Adjust the date to be at the start of the day (to avoid timezone issues)
    date.setUTCHours(date.getUTCHours());

    // Use Intl.DateTimeFormat to format the time
    const formatter = new Intl.DateTimeFormat('en-GB', {
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC'
    });

    return formatter.format(date);
  }

  return (
    <Root ref={locationAreaRef} id='locationAreaRoot'>
      <div className={monitorCallStatus ? 'col-md-12  d-flex flex-column justify-content-center align-item-center' : 'd-none'}>
        {monitorCallHold && 
          <div className='d-flex justify-content-center '>
            <div className='location_area_hold_icon' title='This call is on hold'>
                <PhonePausedOutlinedIcon style={{fontSize:"3rem"}}/>
                <h3>The call is on hold</h3>
            </div> 
          </div>
        }
        <div className="row">
          <div className="col-md-6 p-1 d-flex flex-column text-center">
            <video autoplay="1" id="monitorLocationVideo" playsinline style={{width: '100%'}}></video>
            <audio autoplay="1" id="monitorLocationAudio"></audio>
            <b className='my-1 text-name'>Location</b>
          </div>
          <div className="col-md-6 p-1 d-flex flex-column text-center">
            <video autoplay="1" id="monitorReceptionVideo" playsinline style={{width: '100%'}}></video>
            <audio autoplay="1" id="monitorReceptionAudio"></audio>
            <b className='my-1 text-name'>Reception</b>
          </div>
        </div>
      </div> 
      <video className='location_video' autoPlay='1' id='locationVideo' playsInline 
        style={{display:(locationEngaged)?'flex':"none"}} />
      {locationEngaged && isVideoPaused && 
        <span className='rounded-circle p-2' 
          style={{ position: "absolute", top: "49%", left: "49%", backgroundColor: "#707070"}} 
          title='Your connection is poor. Video is paused to save bandwidth for audio'>
          <VideocamOffIcon fontSize='large' color='warning'/>
        </span>
      }
      <audio autoPlay='1' id='locationAudio' />
      <audio id='alarmAudio' src={AlarmAudio} />
      <div className='my-auto py-auto text-center' style={{position:"absolute"}}>
        {callState && locationEngaged && !micro && <MicOffOutlinedIcon className='location_area_icon' />}
        {callState && locationEngaged && !camera && <VideocamOffOutlinedIcon className='location_area_icon' />}
        {locationEngaged && isCallOnHold && 
          <div className='location_area_hold_icon' title='This call is on hold'>
            <PhonePausedOutlinedIcon style={{fontSize:"3rem"}}/>
            <div className='location_area_hold_icon_timer'>
              {secondsToHms(callHoldTimer)}
            </div>
          </div>
        }
      </div>
      {(locationEngaged || monitorCallStatus)?
        <div className='location_area_locations' style={{ right: (locationsBar)? '0' : '-240px', zIndex: 2 }} >
          <ToggleLocationSidebarButton className='location_area_toggle' style={{display:(locationsBar)?"":"block"}} 
            onClick={toggleLocationsBar}>
              {
                locationsBar?
                <KeyboardArrowRightOutlinedIcon fontSize='large'/>
                :<KeyboardArrowLeftOutlinedIcon fontSize='large'/>
              }
          </ToggleLocationSidebarButton>
          <div className='location_area_list'>
            <ListLocationsElement
              callState={callState}
              handleClickLocationItem={(index) => props.handleClickLocationItem(index)}
              startMonitorCall={(username) => props.startMonitorCall(username)}
              handleItemCall={() => props.handleItemCall()}
              handleClickHoldLocation={props.handleClickHoldLocation}
              remoteLocations={remoteLocations}
              allLocationData={allLocationData}
              localUserData={localUserData}
              handleItemSleep={handleItemSleep}
              width='230px'
              height="130px"
              locationAreaRef={locationAreaRef}
              isRightSideBar={true}
              handleDeviceManagement={handleDeviceManagement}
              handleOpenMessagingBoard={handleOpenMessagingBoard}
              captureLocationCameraImage={captureLocationCameraImage}
            />
          </div>
        </div>
        :
        <div style={{width:"95%", height: "100%"}} ref={refLocationArea}>
          <div className='d-flex flex-wrap' >
          {/* <Grid container spacing={1} direction='row' justifyContent="center" alignItems="center"> */}
            <ListLocationsElement
              callState={callState}
              handleClickLocationItem={(index) => props.handleClickLocationItem(index)}
              startMonitorCall={(username) => props.startMonitorCall(username)}
              handleItemCall={() => props.handleItemCall()}
              handleClickHoldLocation={props.handleClickHoldLocation}
              remoteLocations={remoteLocations}
              allLocationData={allLocationData}
              localUserData={localUserData}
              handleItemSleep={handleItemSleep}
              width={sizeLocationItem[0]}
              height={sizeLocationItem[1]}
              locationAreaRef={locationAreaRef}
              isRightSideBar={false}
              handleDeviceManagement={handleDeviceManagement}
              handleOpenMessagingBoard={handleOpenMessagingBoard}
              captureLocationCameraImage={captureLocationCameraImage}
            />
            </div>
          {/* </Grid> */}
        </div>
      }
    </Root>
  );
});

export default LocationArea;
